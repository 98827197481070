import Div from "@jumbo/shared/Div/Div";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DndWrapper from "app/pages/extensions/dropzone/components/DndWrapper";
import { getAllLanguages } from "app/redux/actions/languageAction";
import { getAllTags } from "app/redux/actions/tagAction";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addTag } from "app/services/apis/addTag";
import { indexOf } from "lodash";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InvoiceBill from "./Dummy";
import { getAllContent } from "app/redux/actions/contentAction";
import Story from "./components/story";
import Visual from "./components/visual";
import Audio from "./components/audio";
import CompleteProject from "./components/completeProject";

export default function EditContent() {
  const { alltags } = useSelector((state) => state.tagReducer);
  const { alllanguages } = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [contentData, setContentData] = useState();
  const [image, setImage] = useState(null);
  const location = useLocation();

  // Access the data passed through the state object
  // const itemData = location.state?.itemData;
  const [itemData, setItemData] = useState([]);
  // Now you can use the itemData object in your component

  // Initialize formData only if contentData is defined

  const handleUpdateAndNext = async () => {
    if (image) {
      try {
        const form = new FormData();
        form.append("id", id); // Assuming you have only one zip file
        form.append("imageFile", image);
        const response = await fetch("/api/v1/content/upload/thumbnail", {
          method: "POST",
          body: form,
        });

        if (response?.ok) {
          const data = await response.json();
          console.log(data);

          setFormData({ ...formData, thumbnail: data?.imageFilePath });
          // console.log("File paths:", data);
          // localStorage.setItem("thumbnail", data.imageFilePath);
          // localStorage.setItem("zip", data.zipFilePath);
          // Handle the file paths in your frontend logic
        } else {
          console.error("Upload failed:", response?.statusText);
          // Handle the error in your frontend logic
        }
      } catch (error) {
        console.error("Upload failed:", error);
        // Handle the error in your frontend logic
      }
    }
    // console.log(formData);
    const updateData = {
      ageRating: formData?.ageRating,
      callToAction: formData?.callToAction,
      language: formData?.language,
      livelink: formData?.liveLink,
      description: formData?.note,
      tags: formData?.tags,
      thumbnail: formData?.thumbnail,
      title: formData?.title,
      privateType: formData?.privateType,
    };
    if (updateData.title && updateData?.tags?.length > 0) {
      axios
        .patch(`/api/v1/content/update/${id}`, updateData)
        .then((response) => {
          // Handle the response if needed
          console.log("Update successful:", response.data);
          // setValue("step2");
          Swal.fire("Details Updated Successfully", "", "success");
          // Add any additional logic or navigation code here
          navigate("/mycontent");
        })
        .catch((error) => {
          // Handle any errors that might occur during the patch request
          Swal.fire("Details Not Updated", "Please Check Your Entry", "error");
          console.error("Error updating data:", error);
        });
    } else {
      if (!updateData?.title && !updateData?.tags?.length)
        Swal.fire({
          icon: "error",
          title: "Fill Title  & Tags",
          text: "",
        });
      else if (!updateData?.tags?.length) {
        Swal.fire({
          icon: "error",
          title: "Fill Tags",
          text: "",
        });
      } else if (!updateData?.title) {
        Swal.fire({
          icon: "error",
          title: "Fill Title",
          text: "",
        });
      }
    }
  };

  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const handleAddChange = () => {
    setOpen(true);
    if (!addButtonClicked) {
      // If the "Add" button is clicked for the first time, update the state to indicate it has been clicked
      setAddButtonClicked(true);
      return; // Return without proceeding with adding the credit on the first click
    }
    // After the first click, proceed with adding the credit
    const updatedData = {
      story: [...itemData.story, { [selectedValue?.name]: inputValue }],
    };

    if (selectedValue?.name && inputValue) {
      axios
        .patch(`/api/v1/content/update/${itemData._id}`, updatedData)
        .then((response) => {
          // Handle the response if needed
          console.log("Update successful:", response.data);

          // Fetch the updated content data after the successful patch request
          axios
            .get(`https://cmsbe.aideo.in/api/v1/mobile/content/${id}`)
            .then((response) => {
              // Handle the response data and update the itemData state
              setItemData(response.data);
            })
            .catch((error) => {
              // Handle errors if any
              console.error("Error:", error.message);
            });

          setOpen(false);
          // Reset the add button state to allow adding more credits
          setAddButtonClicked(false);
          setInputValue("");
          setSelectedValue(null);
          Swal.fire(
            "Details Updated Successfully",
            "Step 1 Updated",
            "success"
          );
          // Add any additional logic or navigation code here
        })
        .catch((error) => {
          // Handle any errors that might occur during the patch request
          setOpen(false);
          setAddButtonClicked(false); // Reset the add button state on error as well
          Swal.fire("Details Not Updated", "Please Check Your Entry", "error");
          console.error("Error updating data:", error);
        });
    } else {
      Swal.fire("Add Credit", "Credit Not Added", "warning");
      setAddButtonClicked(false); // Reset the add button state if the credit was not added
    }
  };
  const handleSave = () => {
    let updatedData = {};
    if (selectedValue?.name && inputValue) {
      updatedData = {
        story: [...itemData.story, { [selectedValue?.name]: inputValue }],
      };
    } else {
      updatedData = {
        story: [...itemData.story],
      };
    }

    axios
      .patch(`/api/v1/content/update/${itemData._id}`, updatedData)
      .then((response) => {
        // Handle the response if needed
        console.log("Update successful:", response.data);

        // Fetch the updated content data after the successful patch request
        axios
          .get(`https://cmsbe.aideo.in/api/v1/mobile/content/${id}`)
          .then((response) => {
            // Handle the response data and update the itemData state
            setItemData(response.data);
          })
          .catch((error) => {
            // Handle errors if any
            console.error("Error:", error.message);
          });

        setOpen(false);
        // Reset the add button state to allow adding more credits
        setAddButtonClicked(false);
        setInputValue("");
        setSelectedValue(null);
        Swal.fire("Details Updated Successfully", "Step 1 Updated", "success");
        // Add any additional logic or navigation code here
      })
      .catch((error) => {
        // Handle any errors that might occur during the patch request
        setOpen(false);
        setAddButtonClicked(false); // Reset the add button state on error as well
        Swal.fire("Details Not Updated", "Please Check Your Entry", "error");
        console.error("Error updating data:", error);
      });
  };

  const [formData, setFormData] = useState({
    title: "",
    callToAction: "",
    tags: "",
    language: "",
    liveLink: "",
    note: "",
    thumbnail: "",
    ageRating: "",
    newImage: "",
    privateType: false,
  });

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(getAllLanguages());
    axios
      .get(`${process.env.REACT_APP_URL}api/v1/mobile/content/${id}`)
      .then((response) => {
        // Handle the response data
        setItemData(response.data);

        // Update the formData state with the received data
        setFormData({
          title: response.data?.title || "",
          callToAction: response.data?.callToAction || "",
          tags: response.data?.tags || "",
          language: response.data?.language || "",
          liveLink: response.data?.livelink || "",
          note: response.data?.description || "",
          thumbnail: response.data?.thumbnail || "",
          ageRating: response.data?.ageRating || "",
          privateType: response.data?.privateType || false,
        });
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error:", error.message);
      });
  }, [dispatch, id]);
  console.log(itemData);
  const handleTagsChange = (event, newValue) => {
    // Check if the newValue is an array
    if (Array.isArray(newValue)) {
      // Map over the newValue and convert strings to objects with the 'name' property
      const transformedTags = newValue.map((tag) => {
        // Check if the tag is a string
        if (typeof tag === "string") {
          // Check if the tag already exists in alltags
          const existingTag = alltags.find((item) => item.name === tag);
          if (existingTag) {
            // If the tag exists, return the existing tag object
            return existingTag;
          } else {
            // If the tag doesn't exist, add it as a new tag using addTag API
            return addTag({ name: tag })
              .then((res) => {
                if (res?.data) {
                  dispatch(getAllTags());
                  setFormData({
                    ...formData,
                    tags: [...formData.tags, res.data],
                  });
                  return res.data;
                }
              })
              .catch((res) => {});
          }
        } else {
          // If the tag is not a string, return it as is
          return tag;
        }
      });

      // Wait for all the promises to resolve before updating the state
      Promise.all(transformedTags).then((tags) => {
        setFormData({ ...formData, tags });
      });
    } else {
      // If newValue is not an array, directly set it as the tags state
      setFormData({ ...formData, tags: newValue });
    }
  };

  const [open, setOpen] = useState(false);
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/content/getCredits/")
      .then((response) => {
        setCredits(response.data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }, []);

  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [selectedValue, setSelectedValue] = useState(null);

  const handleAutocompleteChange = (event, newValue) => {
    console.log(newValue);
    if (typeof newValue === "string") {
      // User entered a value not in the options list
      setSelectedValue({ name: newValue });
    } else {
      // User cleared the value
      setSelectedValue(newValue);
    }
  };

  const handleStoryChange = (index, key, value) => {
    const updatedStory = [...itemData.story];
    updatedStory[index] = { [key]: value };
    setItemData({ ...itemData, story: updatedStory });
  };

  const handleDeleteNewStory = () => {
    setOpen(false);
    setSelectedValue(null);
    setInputValue("");
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    displayImage(selectedImage);

    // // Assuming you want to set the image filename in the formData
    // setFormData({
    //   ...formData,
    //   thumbnail: selectedImage.name,
    // });
  };
  const displayImage = (selectedFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target.result;
      console.log(imageUrl);
      document.getElementById("image-preview").src = imageUrl;
    };

    reader.readAsDataURL(selectedFile);
  };
  console.log(formData, "formmmmm");
  return (
    <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
      <Card sx={{ width: "50%" }}>
        <CardContent>
          <Typography component={"h2"} variant="h1" mb={3}>
            Edit Details
          </Typography>
          <Box
            component="form"
            sx={{
              mx: -1,

              "& .MuiFormControl-root:not(.MuiTextField-root)": {
                p: 1,
                mb: 2,
                width: { xs: "100%", sm: "50%" },
              },

              "& .MuiFormControl-root.MuiFormControl-fluid": {
                width: "100%",
              },
            }}
            autoComplete="off"
          >
            <FormControl>
              <TextField
                fullWidth
                id="title"
                required
                label="Title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                // required
                id="callToAction"
                label="Call To Action"
                value={formData.callToAction}
                onChange={(e) =>
                  setFormData({ ...formData, callToAction: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <Div sx={{ width: 500, maxWidth: "100%" }}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={alltags}
                  getOptionLabel={(option) => option.name}
                  value={formData?.tags}
                  onChange={handleTagsChange}
                  freeSolo={true}
                  required
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Choose Tags*"
                      placeholder="Tags"
                    />
                  )}
                  // createOption={handleCreateOption}
                />
              </Div>
            </FormControl>
            <FormControl>
              <Div sx={{ width: 500, maxWidth: "100%" }}>
                <Autocomplete
                  multiple={false}
                  id="language-standard"
                  options={alllanguages}
                  getOptionLabel={(option) => option.name}
                  value={formData.language}
                  onChange={(event, value) => {
                    // console.log(value.name);
                    setFormData({ ...formData, language: value });
                  }}
                  limitTags={1}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Choose Language"
                      placeholder="Language"
                    />
                  )}
                />
              </Div>
            </FormControl>
            <FormControl className="MuiFormControl-fluid">
              <TextField
                fullWidth
                id="liveLink"
                label="Live Link"
                value={formData.liveLink}
                onChange={(e) =>
                  setFormData({ ...formData, liveLink: e.target.value })
                }
              />
            </FormControl>
            <FormControl className="MuiFormControl-fluid">
              <TextField
                id="help"
                multiline
                rows={4}
                label="Note"
                value={formData.note}
                onChange={(e) =>
                  setFormData({ ...formData, note: e.target.value })
                }
              />
            </FormControl>

            {formData?.thumbnail && image == null && (
              <FormControl sx={{ minWidth: "100%" }}>
                <Typography variant="h5">Thumbnail</Typography>
                <img
                  src={`${process.env.REACT_APP_URL}/${formData?.thumbnail}`}
                  alt="Thumbnail"
                  style={{ width: "200px", height: "200px" }}
                />
              </FormControl>
            )}
            {image && (
              <FormControl sx={{ minWidth: "100%" }}>
                <Typography variant="h5">Thumbnail</Typography>
                <img
                  id="image-preview"
                  src={image}
                  alt="Thumbnail"
                  style={{ width: "200px", height: "200px" }}
                />
              </FormControl>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{
                marginLeft: 10,
                minWidth: "100%",
                marginBottom: 20,
              }}
            />
            <FormControl>
              <Typography variant="h6">Age Rating</Typography>
              <Select
                labelId="age-rating-label"
                id="age-rating"
                value={formData.ageRating}
                onChange={(event) =>
                  setFormData({ ...formData, ageRating: event.target.value })
                }
                label="Age Rating"
                fullWidth
              >
                <MenuItem value={"Adult"}>Adult</MenuItem>
                <MenuItem value={"Naughty"}>Naughty</MenuItem>
                <MenuItem value={"Universal"}>Universal</MenuItem>
              </Select>
            </FormControl>

            <Story data={itemData} />

            <Visual data={itemData} />

            <Audio data={itemData} />

            <CompleteProject data={itemData} />

            <Div sx={{ mt: 5, display: "flex", alignItems: "center", gap: 3 }}>
              <Typography variant="h4" sx={{ pt: 1 }}>
                Private:
              </Typography>
              {console.log(formData.privateType)}
              <Select
                sx={{ width: "100px" }}
                variant="standard"
                value={formData?.privateType}
                label="Private"
                onChange={(event) =>
                  setFormData({ ...formData, privateType: event.target.value })
                }
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </Div>
            <Div sx={{ mx: 1 }}>
              <Button variant="contained" onClick={handleUpdateAndNext}>
                Update
              </Button>
            </Div>
          </Box>
        </CardContent>
      </Card>
    </Div>
  );
}
