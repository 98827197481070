import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getAllLanguages } from "app/redux/actions/languageAction";
import { getAllTags } from "app/redux/actions/tagAction";
import { addCredits } from "app/services/apis/addCredit";

export default function Story({ data }) {
  console.log(data);
  const [selectedValue, setSelectedValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [itemData, setItemData] = useState(data);
  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [credits, setCredits] = useState([]);
  const dispatch = useDispatch();
  console.log(itemData);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    setItemData(data);
  }, [data]);

  useEffect(() => {
    axios
      .get("/api/v1/content/getCredits/")
      .then((response) => {
        setCredits(response.data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddChange = () => {
    setOpen(true);
    if (!addButtonClicked) {
      // If the "Add" button is clicked for the first time, update the state to indicate it has been clicked
      setAddButtonClicked(true);
      return; // Return without proceeding with adding the credit on the first click
    }
    // After the first click, proceed with adding the credit
    const updatedData = {
      story: [...itemData.story, { [selectedValue?.name]: inputValue }],
    };

    if (selectedValue?.name && inputValue) {
      axios
        .patch(`/api/v1/content/update/${itemData._id}`, updatedData)
        .then((response) => {
          // Handle the response if needed
          console.log("Update successful:", response.data);

          // Fetch the updated content data after the successful patch request
          axios
            .get(`https://cmsbe.aideo.in/api/v1/mobile/content/${id}`)
            .then((response) => {
              // Handle the response data and update the itemData state
              setItemData(response.data);
            })
            .catch((error) => {
              // Handle errors if any
              console.error("Error:", error.message);
            });

          setOpen(false);
          // Reset the add button state to allow adding more credits
          setAddButtonClicked(false);
          setInputValue("");
          setSelectedValue(null);
          Swal.fire(
            "Details Updated Successfully",
            "Step 1 Updated",
            "success"
          );
          // Add any additional logic or navigation code here
        })
        .catch((error) => {
          // Handle any errors that might occur during the patch request
          setOpen(false);
          setAddButtonClicked(false); // Reset the add button state on error as well
          Swal.fire("Details Not Updated", "Please Check Your Entry", "error");
          console.error("Error updating data:", error);
        });
    } else {
      Swal.fire("Add Credit", "Credit Not Added", "warning");
      setAddButtonClicked(false); // Reset the add button state if the credit was not added
    }
  };

  const handleSave = () => {
    let updatedData = {};
    if (selectedValue?.name && inputValue) {
      updatedData = {
        story: [...itemData.story, { [selectedValue?.name]: inputValue }],
      };
      addCredits([selectedValue?.name]);
    } else {
      updatedData = {
        story: [...itemData.story],
      };
    }

    axios
      .patch(`/api/v1/content/update/${itemData._id}`, updatedData)
      .then((response) => {
        // Handle the response if needed
        console.log("Update successful:", response.data);

        // Fetch the updated content data after the successful patch request
        axios
          .get(`https://cmsbe.aideo.in/api/v1/mobile/content/${id}`)
          .then((response) => {
            // Handle the response data and update the itemData state
            setItemData(response.data);
          })
          .catch((error) => {
            // Handle errors if any
            console.error("Error:", error.message);
          });

        setOpen(false);
        // Reset the add button state to allow adding more credits
        setAddButtonClicked(false);
        setInputValue("");
        setSelectedValue(null);
        Swal.fire("Details Updated Successfully", "Step 1 Updated", "success");
        // Add any additional logic or navigation code here
      })
      .catch((error) => {
        // Handle any errors that might occur during the patch request
        setOpen(false);
        setAddButtonClicked(false); // Reset the add button state on error as well
        Swal.fire("Details Not Updated", "Please Check Your Entry", "error");
        console.error("Error updating data:", error);
      });
  };

  const handleAutocompleteChange = (event, newValue) => {
    console.log(newValue);
    if (typeof newValue === "string") {
      // User entered a value not in the options list
      setSelectedValue({ name: newValue });
    } else {
      // User cleared the value
      setSelectedValue(newValue);
    }
  };

  const handleStoryChange = (index, key, value) => {
    const updatedStory = [...itemData.story];
    updatedStory[index] = { [key]: value };
    setItemData({ ...itemData, story: updatedStory });
  };

  const handleDeleteNewStory = () => {
    setOpen(false);
    setSelectedValue({});
    setInputValue("");
  };
  return (
    <FormControl className="MuiFormControl-fluid">
      <Div sx={{ minWidth: "100%" }}>
        <Typography>Story</Typography>
        {itemData?.story ? (
          <Div>
            {itemData?.story.map((item, index) => {
              const key = Object.keys(item)[0];
              const value = Object.values(item)[0];

              return (
                <Div sx={{ display: "flex", gap: 5, mt: 2 }} key={index}>
                  <Autocomplete
                    // freeSolo
                    multiple={false}
                    id={`story-${index}`}
                    options={credits}
                    getOptionLabel={(option) => option.name || ""}
                    value={{ name: key }} // Set the value as an object with the 'name' property
                    onChange={(e, newValue) =>
                      handleStoryChange(index, newValue.name, value)
                    }
                    sx={{ width: "30%" }}
                    disableClearable
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          "& > img": { mr: 2, flexShrink: 0 },
                        }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Story Creditor"
                        placeholder="Name"
                      />
                    )}
                  />
                  <Input
                    sx={{ width: "30%" }}
                    value={value}
                    onChange={(e) =>
                      handleStoryChange(index, key, e.target.value)
                    }
                  />
                  <IconButton
                    onClick={() => {
                      const updatedStory = itemData.story.filter(
                        (_, i) => i !== index
                      );
                      setItemData({ ...itemData, story: updatedStory });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Div>
              );
            })}
          </Div>
        ) : null}
        {open && (
          <Div
            className="MuiFormControl-fluid"
            sx={{ display: "flex", gap: 5, mt: 2, alignItems: "end" }}
          >
            <Autocomplete
              freeSolo
              multiple={false}
              id="story"
              options={credits}
              getOptionLabel={(option) => option.name || ""}
              value={selectedValue}
              onChange={handleAutocompleteChange}
              sx={{ width: "30%" }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    "& > img": { mr: 2, flexShrink: 0 },
                  }}
                  {...props}
                >
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Story Creditor"
                  placeholder="Name"
                />
              )}
            />
            <TextField
              sx={{ width: "30%" }}
              key="value"
              value={inputValue}
              onChange={handleInputChange}
              variant="standard"
            />
            <IconButton
              onClick={() => {
                handleDeleteNewStory();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Div>
        )}
        <Button
          onClick={() => {
            handleAddChange();
          }}
        >
          Add
        </Button>

        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </Div>
    </FormControl>
  );
}
