const layoutConfig = {
  sidebar: {
    hide: true,
  },
  content: {
    sx: {
      p: 0,
      px: { lg: 0, xs: 0 },
    },
  },
  header: {
    hide: true,
  },
  footer: {
    hide: true,
  },
};

export default layoutConfig;
