import axios from "axios";

export const contentDelete = async (ids) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const data = await axios.post(
      "/api/v1/admin/delete/logs",
      { contentIds: ids },
      config
    );
    return data;
  } catch (error) {
    // console.log(data)
    return error.response.data;
  }
};
