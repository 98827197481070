import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { CardActions, Link, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Divider from "@mui/material/Divider";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";
import { latestNotifications } from "./data";
import Step1Form from "./components/Step1Form";
import Step2Form from "./components/Step2Form";
import Step3Form from "./components/Step3Form";
import { useParams } from "react-router-dom";
import axios from "axios";

const NotificationListComponents = {
  // "MESSAGES": MessagesList,
  // "FEEDS": FeedsList,
  // "INVITATIONS": InvitationsList
};

const LatestAlerts = ({ scrollHeight }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("step1");
  const params = useParams();
  const { id } = params;
  const [contentData, setContentData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cmsbe.aideo.in/api/v1/mobile/content/${id}`
        );
        // Assuming the response data is an object, you can store it in the contentData state
        setContentData(response.data);
        setLoading(false); // Set loading to false once the data is fetched
      } catch (error) {
        // Handle any errors that might occur during the GET request
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, []);

  // If loading is true, render a loading state or null
  if (loading) {
    return <div>Loading...</div>; // You can customize the loading state here
  }

  // If contentData is not null, render the TabPanel components with data
  return (
    <JumboCardQuick noWrapper title={"Add Aideo"} sx={{ width: "70%" }}>
      <TabContext value={value}>
        <TabList sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tab label={"Step 1"} value={"step1"} sx={{ flex: "1 1 auto" }} />
          <Tab label={"Step 2"} value={"step2"} sx={{ flex: "1 1 auto" }} />
          <Tab label={"Step 3"} value={"step3"} sx={{ flex: "1 1 auto" }} />
        </TabList>
        <TabPanel value="step1" sx={{ p: 0 }}>
          <JumboScrollbar
            autoHeight
            autoHeightMin={scrollHeight ? scrollHeight : 900}
            autoHide
            autoHideDuration={200}
            autoHideTimeout={500}
          >
            <Step1Form setValue={setValue} data={contentData} />
          </JumboScrollbar>
        </TabPanel>
        <TabPanel value="step2" sx={{ p: 0 }}>
          <JumboScrollbar
            autoHeight
            autoHeightMin={scrollHeight ? scrollHeight : 700}
            autoHide
            autoHideDuration={200}
            autoHideTimeout={500}
          >
            <Step2Form setValue={setValue} data={contentData} />
          </JumboScrollbar>
        </TabPanel>
        <TabPanel value="step3" sx={{ p: 0 }}>
          <JumboScrollbar
            autoHeight
            autoHeightMin={scrollHeight ? scrollHeight : 608}
            autoHide
            autoHideDuration={200}
            autoHideTimeout={500}
          >
            <Step3Form data={contentData} />
          </JumboScrollbar>
        </TabPanel>
      </TabContext>
      <Divider />
    </JumboCardQuick>
  );
};

export default LatestAlerts;
