import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Div from "@jumbo/shared/Div/Div";

export default function InvoiceBill({ item }) {
  return <Div></Div>;
}
