import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Div from "@jumbo/shared/Div/Div";
import { BACKEND_URL } from "app/utils/constants/paths";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
const styles = {
  mediaPlayer: {
    position: "relative",
  },
  videoPlayer: {
    width: "400px",
    height: "auto",
  },
  image: {
    background: "transparent",
  },
  audioPlayer: {
    display: "block",
    marginLeft: "100px",
  },
  imageOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
  overlayImage: {
    width: "400px",
    height: "600px",
  },
  arrowButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: "none",
    border: "none",
    color: "black",
    fontSize: "24px",
    zIndex: 2,
  },
  nextButton: {
    backgroundColor: "transparent",
    height: "130%",
    width: "15%",
    right: 0,
  },
  prevButton: {
    left: 0,
    height: "130%",
    width: "15%",
    backgroundColor: "transparent",
  },
  pauseButton: {
    position: "absolute",
    // marginLeft: "350px",
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    marginLeft: "43%",
  },
};

let id;
const url = `${BACKEND_URL}controllers/Content/uploads/`;

const MediaPlayer = ({ data, setOpen }) => {
  let { contentData, _id } = data;
  id = _id;
  contentData = JSON.parse(contentData);
  return (
    <div style={styles.mediaPlayer}>
      <ImageOverlay data={contentData} setOpen={setOpen} />
    </div>
  );
};

const VideoPlayer = ({ videoSource, audioSource, isPaused }) => {
  const videoPlayerStyle = {
    width: "400px",
    height: "auto",
  };

  return (
    <div style={styles.videoPlayer}>
      {videoSource && (
        <ReactPlayer
          url={videoSource}
          playing={!isPaused} // Use the 'playing' prop to control play/pause
          loop
          width="100%"
          height="100%"
        />
      )}
      {audioSource && (
        <audio autoPlay loop={!isPaused} id="myAudio">
          <source src={`${url}${id}/${audioSource}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {!videoSource && (
        <Div
          sx={{
            width: "400px",
            height: "750px",
            zIndex: -2,
            backgroundColor: "black",
          }}
        ></Div>
      )}
    </div>
  );
};

const ImageOverlay = ({ data, setOpen }) => {
  // console.log(data);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [lastImage, setLastImage] = useState(false);
  const segments = data.Segments;
  const images = segments.map((segment) => segment.Image);
  const defaultInterval = 5000;
  const audioRef = useRef(null);

  const handleNextImage = () => {
    if (!isPaused) {
      stopAudio();
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const handleVideoEnded = () => {
    if (currentImageIndex == images.length - 1) {
      setOpen(false);
    }
    if (!isPaused) {
      handleNextImage();
    }
  };

  const handlePrevImage = () => {
    if (!isPaused) {
      stopAudio();
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };

  const playImageAudio = () => {
    const audioSource = segments[currentImageIndex]?.Audio?.Source;
    if (audioSource) {
      // console.log("playImageAudio");
      const audioElement = new Audio(`${url}/${id}/${audioSource}`);
      audioRef.current = audioElement;
      audioElement.play();
      audioElement.addEventListener("loadedmetadata", () => {
        const audioLengthInSeconds = audioElement.duration;
        // console.log(`Audio length: ${audioLengthInSeconds} seconds`);
        setAudioDuration(audioLengthInSeconds * 1000);
        // You can do something else with the audio length here.
      });
    }
  };

  const durationInterval = () => {
    if (audioDuration > 0) {
      if (audioDuration > images[currentImageIndex]?.Interval) {
        // console.log("audio", audioDuration);
        return audioDuration;
      } else {
        // console.log("default");
        return images[currentImageIndex]?.Interval;
      }
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    return () => {
      stopAudio();
    };
  }, []);

  useEffect(() => {
    if (currentImageIndex == images.length - 1) {
      setLastImage(true);
    }
    if (lastImage == false) {
      playImageAudio();
    }
  }, [currentImageIndex]);

  const intervalRef = useRef(durationInterval());

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      handleNextImage();
    }, durationInterval() || defaultInterval);
  }, []);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (audioRef?.current?.currentTime) {
      intervalRef.current = setInterval(() => {
        handleNextImage();
      }, audioDuration - audioRef.current.currentTime * 1000 || defaultInterval);
    } else if (isVideoSegment) {
      intervalRef.current = setInterval(() => {
        handleNextImage();
      }, videoDuration * 1000 || defaultInterval);
    } else {
      intervalRef.current = setInterval(() => {
        handleNextImage();
      }, durationInterval() || defaultInterval);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [currentImageIndex, audioDuration, isPaused, videoDuration]);

  const [playedDuration, setPlayedDuration] = useState(0); // Track played duration in milliseconds

  const handleProgress = (state) => {
    // Update the played duration when the onProgress event is triggered
    // console.log(state.playedSeconds);
    setPlayedDuration(state.playedSeconds * 1000);
  };
  //when last image is played i want the whole player to stop but when it is stopping it is throwing error that The play() request was interrupted by a call to pause()
  useEffect(() => {
    let endTime;
    // console.log(intervalRef.current);
    console.log(audioDuration);
    console.log(videoDuration);

    console.log(audioRef?.current?.currentTime);

    if (currentImageIndex == images.length - 1) {
      if (audioDuration == 0 && videoDuration > 0) {
        if (!isPaused) {
          endTime = setTimeout(() => {
            setOpen(false);
          }, videoDuration * 1000 - playedDuration);
        }
      } else {
        if (currentImageIndex == images.length - 1) {
          if (!isPaused) {
            if (audioRef?.current?.currentTime != undefined) {
              console.log("2");
              endTime = setTimeout(() => {
                setOpen(false);
              }, audioDuration - audioRef?.current?.currentTime * 1000);
            } else {
              // console.log(intervalRef.current * 1000);
              console.log("3");
              endTime = setTimeout(() => {
                setOpen(false);
              }, images[currentImageIndex]?.Interval - intervalRef.current);
            }
          }
        }
      }
    }
    return () => {
      clearTimeout(endTime);
    };
  }, [currentImageIndex, audioRef, isPaused]);

  const loaders = images.map((image, index) => (
    <progress
      key={index}
      value={currentImageIndex === index ? 100 : 0}
      max={100}
      style={{
        margin: "0 2px",
        flex: `1 1 auto`,
        maxWidth: `calc(80% / ${images.length})`,
        zIndex: 5,
      }}
    />
  ));

  const currentSegment = segments[currentImageIndex];
  const isVideoSegment = currentSegment?.Video?.Source;

  const handlePause = () => {
    setIsPaused(!isPaused);

    let x = document.getElementById("myAudio");
    if (x) {
      if (!isPaused) {
        x.pause();
      } else {
        console.log("handlepause");
        x.play();
      }
    }
    clearInterval(intervalRef.current);

    if (isPaused) {
      if (audioRef.current) {
        // alert("rehan");
        audioRef.current.play();
      }
    } else {
      if (audioRef.current) {
        // console.log("pathan");
        audioRef.current.pause();
      }
    }
  };

  return (
    <div style={styles.mediaPlayer}>
      <VideoPlayer
        videoSource={
          data?.Background?.Video?.Source
            ? `${url}/${id}/${data.Background.Video.Source}`
            : null
        }
        audioSource={data?.Background?.Audio?.Source}
        isPaused={isPaused}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          maxWidth: "auto",
        }}
      >
        {loaders}
      </div>

      <div style={styles.imageOverlay}>
        {isVideoSegment ? (
          <ReactPlayer
            url={`${url}/${id}/${currentSegment.Video.Source}`}
            playing={!isPaused}
            width="400px"
            height="auto"
            onEnded={handleVideoEnded}
            onDuration={(duration) => setVideoDuration(duration)}
            onProgress={handleProgress}
          />
        ) : (
          <img
            style={{ ...styles.overlayImage, background: "transparent" }}
            src={`${url}/${id}/${images[currentImageIndex]?.Source}`}
            alt={`Image ${currentImageIndex + 1}`}
          />
        )}
        <button
          tabIndex="-1"
          style={{ ...styles.arrowButton, ...styles.prevButton }}
          onClick={handlePrevImage}
        ></button>
        <button
          tabIndex="-1"
          style={{ ...styles.arrowButton, ...styles.nextButton }}
          onClick={handleNextImage}
        ></button>
      </div>
      <button style={{ ...styles.pauseButton }} onClick={handlePause}>
        {isPaused ? (
          <PlayCircleOutlineIcon sx={{ width: "60px", height: "60px" }} />
        ) : (
          <PauseCircleOutlineIcon
            sx={{ width: "60px", height: "60px", p: 0 }}
          />
        )}
      </button>
    </div>
  );
};

export default MediaPlayer;
